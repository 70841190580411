<template>
<a-layout-content>
    <div class="header_div">
        <!-- <h4>Hello {{ username  }},</h4> -->
		<br>
		<br>
    </div>
    <div>

		<!-- Header Background Image -->
		<div class="profile-nav-bg" style="background-image: url('images/bg-profile.jpg')"></div>
		<!-- / Header Background Image -->

		<!-- User Profile Card -->
		<a-card :bordered="false" class="card-profile-head" :bodyStyle="{padding: 0,}">
			<template #title>
				<a-row type="flex" align="middle">
					<a-col :span="24" :md="12" class="col-info">
						<!-- <a-avatar :size="74" shape="square" src="images/face-1.jpg" /> -->
						<div class="avatar-info">
							<h4 class="font-semibold m-0">Hello {{ username  }},</h4>
							<p>Profile management</p>
						</div>
					</a-col>
					<a-col :span="24" :md="12" style="display: flex; align-items: center; justify-content: flex-end">
						<!-- <a-radio-group v-model="profileHeaderBtns" size="small">
							<a-radio-button value="overview">OVERVIEW</a-radio-button>
							<a-radio-button value="teams">TEAMS</a-radio-button>
							<a-radio-button value="projects">PROJECTS</a-radio-button>
						</a-radio-group> -->
					</a-col>
				</a-row>
			</template>
		</a-card>
		<!-- User Profile Card -->

		<a-row type="flex" :gutter="24">

			<!-- Platform Settings Column -->
			<a-col :span="24" :md="8" class="mb-24">

				<!-- Platform Settings Card -->
				<CardPlatformSettings></CardPlatformSettings>
				<!-- / Platform Settings Card -->

			</a-col>
			<!-- / Platform Settings Column -->

			<!-- Profile Information Column -->
			<a-col :span="24" :md="8" class="mb-24">

				<!-- Profile Information Card -->
				<CardProfileInformation></CardProfileInformation>
				<!-- / Profile Information Card -->

			</a-col>
			<!-- / Profile Information Column -->
			
			<!-- Conversations Column -->
			<a-col :span="24" :md="8" class="mb-24">
			
				<!-- Conversations Card -->
				<CardConversations
					:data="conversationsData"
				></CardConversations>
				<!-- / Conversations Card -->

			</a-col>
			<!-- / Conversations Column -->

		</a-row>
		
		<!-- Projects Card -->
		 <a-card :bordered="false" class="header-solid h-full mb-24" :bodyStyle="{paddingTop: '14px'}">
			<template #title>
				<h6 class="font-semibold">Workflows</h6>			
				<p>Mailing and notification status per workflow</p>	
			</template>

			   <a-table 
        rowKey="id"
        :columns="columns"
        :data-source="data"
        :loading="loading"
        size="middle"
        @change="handleTableChange"
        :pagination="pagination"
    >
      
      
        <span slot="thumbnail" slot-scope="text,record">
            
            <div v-if="record.thumbnail" class="thumbnail" :style="`background-color:#202020;background-image:url(${record.thumbnail});background-repeat:no-repeat;background-position: inherit;
    background-size: cover;
    height: 100px;border-radius: 5px;`">

            </div>
            <div v-else class="thumbnail">
                No retracting map
            </div>
        </span> 
		<span slot="name" slot-scope="text,record">
            <a-tooltip placement="top">
            
            <b> {{ text }}</b>
            
             </a-tooltip> 
        </span>
        <a-tag slot="type_name" slot-scope="text" color="#348ceb">
            {{ text }}
        </a-tag>

        

        <a-tag slot="nick_name" slot-scope="text" color="blue">
            {{ text }}
        </a-tag>

        <span slot="update_time" slot-scope="text">
            {{Dayjs(text).format('YYYY-MM-DD HH:mm:ss')}}
        </span>

        <span slot="create_time" slot-scope="text">
            {{Dayjs(text).format('YYYY-MM-DD HH:mm:ss')}}
        </span>

        <span slot="remarks" slot-scope="text">
            {{text}}
        </span>

        <div slot="status" slot-scope="text,record">
            <a-switch default-checked v-if="text===0" @click="onSwitch($event,record.id)">
                <a-icon slot="checkedChildren" type="check" />
                <a-icon slot="unCheckedChildren" type="close" />
            </a-switch>
            <a-switch v-else @click="onSwitch($event,record.id)">
                <a-icon slot="checkedChildren" type="check" />
                <a-icon slot="unCheckedChildren" type="close" />
            </a-switch>
        </div>
    </a-table>
		</a-card> 

	</div>

</a-layout-content>
</template>

<script>


import CardPlatformSettings from "../../components/Cards/CardPlatformSettings"
import CardProfileInformation from "../../components/Cards/CardProfileInformation"
import CardConversations from "../../components/Cards/CardConversations"
import CardProject from "../../components/Cards/CardProject"

	// Conversation's list data.
	const conversationsData = [
		{
			id: "1",
			title: "Sophie B.",
			code: "Hi! I need more information…",
			avatar: "images/face-3.jpg",
		},
		{
			id: "2",
			title: "Anne Marie",
			code: "Awesome work, can you…",
			avatar: "images/face-4.jpg",
		},
		{
			id: "3",
			title: "Ivan",
			code: "About files I can…",
			avatar: "images/face-5.jpeg",
		},
		{
			id: "4",
			title: "Peterson",
			code: "Have a great afternoon…",
			avatar: "images/face-6.jpeg",
		},
		{
			id: "5",
			title: "Nick Daniel",
			code: "Hi! I need more information…",
			avatar: "images/face-2.jpg",
		},
	] ;

	// Project cards data
	const projects = [
		{
			id: 1,
			title: "Modern",
			content: "As Uber works through a huge amount of internal management turmoil.",
			cover: "images/home-decor-3.jpeg",
			team: [
				"images/face-1.jpg",
				"images/face-4.jpg",
				"images/face-2.jpg",
				"images/face-3.jpg",
			],
		},
		{
			id: 2,
			title: "Scandinavian",
			content: "Music is something that every person has his or her own specific opinion about.",
			cover: "images/home-decor-2.jpeg",
			team: [
				"images/face-1.jpg",
				"images/face-4.jpg",
				"images/face-2.jpg",
				"images/face-3.jpg",
			],
		},
		{
			id: 3,
			title: "Minimalist",
			content: "Different people have different taste, and various types of music, Zimbali Resort.",
			cover: "images/home-decor-1.jpeg",
			team: [
				"images/face-1.jpg",
				"images/face-4.jpg",
				"images/face-2.jpg",
				"images/face-3.jpg",
			],
		},
	] ;

	const columns = [
    // {
    //     title: "Thumbnail",
    //     key: "thumbnail",
    //     scopedSlots: {
    //         customRender: "thumbnail",
    //     },
    //     // width: 200,
    // }, 
    // {
    //     title: "Type",
    //     key: "type",
    //     scopedSlots: {
    //         customRender: "type",
    //     },
    //     sorter: true,

    // }, 
	{
        title: "Workflow",
        dataIndex: "name",
        key: "name",
        scopedSlots: {
            customRender: "name",
        },
        sorter: true,
    },
	{
        title: "Namespace",
        dataIndex: "type_name",
        key: "type_name",
        scopedSlots: {
            customRender: "type_name",
        },
        sorter: true,
    },
	 
    {
        title: "User",
        dataIndex: "nick_name",
        key: "nick_name",
        scopedSlots: {
            customRender: "nick_name",
        },
        sorter: true,
    },
    {
        title: "Update time",
        key: "update_time",
        dataIndex: "update_time",
        scopedSlots: {
            customRender: "update_time",
        },
        sorter: true,
    },
    {
        title: "Status",
        key: "status",
        dataIndex: "status",
        scopedSlots: {
            customRender: "status",
        },
    }
];
export default {
    name: "profileHome",
    components: {
			CardPlatformSettings,
			CardProfileInformation,
			CardConversations,
			CardProject,
		},
    data() {
        return {
			columns,
			data: [],
			type_data: [],
			select_type: "0",
			pagination: {
                total: 0,
                defaultPageSize: 10,
                showTotal: total => `common ${total} Article data`,
                showSizeChanger: true,
                pageSizeOptions: ['10', '15', '20', '50', '100'],
                onShowSizeChange: this.onPageShowSizeChange,
                onChange: this.onPageChange
            },
            curr_page: 1,
            curr_page_size: 10,
            // Profile specific
            username: "",
            profileHeaderBtns: 'overview',

            // Associating Conversation's list data with its corresponding property.
            conversationsData,

            // Project cards data
            projects,
        };
    },
    mounted() {
        this.onLoad();
    },
    methods: {
		onPageShowSizeChange(current, pageSize) {
            this.curr_page = current;
            this.curr_page_size = pageSize;
            this.onLoad(this.so_text, this.select_type, current, pageSize);
        },
        onPageChange(page, pageSize) {
            this.curr_page = page;
            this.curr_page_size = pageSize;
            this.onLoad(this.so_text, this.select_type, page, pageSize);
        },
        handleTableChange(page) {
            //
        },
		onSelectType() {
            this.$http
                .post("/api/v1/rest/get/type/list", {
                    type: 1,
                })
                .then((res) => {
                    if (res.code == 0) {
                        this.type_data = res.data;
                    } else {
                        this.$message.error(res.msg);
                    }
                });
        },
        onLoad() {
            this.username = $cookies.get("account");
            this.loading = true;
            this.onSelectType();
            this.$http
                .post("/api/v1/rest/get/workflow/list", {
                    keywords: "",
                    type: "0",
                    page: 1,
                    page_count: 10
                })
                .then((res) => {
                    if (res.code == 0) {
                        this.data = res.data.list;
						try {
							this.pagination.total = res.data.total_count;	
						} catch (error) {
							
						}
                        
                        this.loading = false;
                    } else {
                        this.$message.error(res.msg);
                        this.loading = false;
                    }
                });
        }

    },
};
</script>

<style lang="less" scoped>
@import url("../../theme/style.less");

.pointer {
    cursor: pointer;
}

.center {
    text-align: center;
}

.bright {
    .header_div {
        width: 100%;
        background: #ffffff;
        margin-bottom: 10px;
        padding: 10px;
        border-radius: 8px;

        .align {
            vertical-align: middle;
        }

        .btn_add {
            float: right;
        }
    }

    .ant-table-wrapper {
        background: #fff;
        border-radius: 8px;
        padding: 5px 10px;

        /deep/ .ant-table-thead>tr>th {
            background: #fff;
        }
    }
}

.dark {
    .header_div {
        width: 100%;
        background: #202020;
        margin-bottom: 10px;
        padding: 10px;
        border-radius: 8px;

        .align {
            vertical-align: middle;
        }

        .btn_add {
            float: right;
        }
    }

    .ant-table-wrapper {
        background: #202020;
        border-radius: 8px;
        padding: 5px 10px;

        /deep/ .ant-table-thead>tr>th {
            background: #202020;
            border-bottom: 1px solid #303030;
        }
    }
}


</style>
